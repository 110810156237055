import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Homepage from './Homepage';
import Dashboard from './Dashboard';
import PhoneAuthentication from './PhoneAuthentication';
import Dog404 from './Dog404';
import reportWebVitals from './reportWebVitals';
import { ErrorBoundary } from "react-error-boundary";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import { Navbar } from './Navbar';
import SchedulePage from './SchedulePage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Homepage />,
    errorElement: <Dog404 />,
  },
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "schedule",
    element: <SchedulePage />,
  },
  {
    path: "auth",
    element: <PhoneAuthentication />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<p>⚠️Something went wrong</p>}>
      <RouterProvider
        router={router}
        fallbackElement={<Dog404 />}
      />
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
