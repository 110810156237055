import React, { useEffect, useState } from 'react';
import lalirose from './lali_bg.jpg';
import './App.css';
import {
  getAuth,
  signOut,
} from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './authentication/config';
import { Link } from "react-router-dom";
import { doc, setDoc, getDoc, updateDoc, deleteDoc, getFirestore } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { BurgerMenu } from './BurgerMenu';

export function Navbar() {
    const [scrolled, setScrolled] = useState(false);
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
          const scrollTop = window.scrollY;
          if (scrollTop > 50) {
            setScrolled(true);
          } else {
            setScrolled(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

      //   <div style={{ color: "#D69957" }}>Menu</div>
      return (
          <nav style={{
            padding: "10px 30px",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            transition: "all .2s",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: scrolled ? "#FFF" : "#202020",
          }}>
            <div
                style={{
                    color: scrolled ? "#000" : "#FFF",
                    cursor: "pointer",
                    fontSize: "16px",
                 }}
                onClick={(e) => {
                    e.preventDefault();
                    navigate("/");
                    window.scrollTo(0, 0)
                }}
            >
                HOME
                {/* <BurgerMenu /> */}
            </div>
            <div
                style={{
                    color: scrolled ? "#000" : "#FFF",
                    fontSize: "32px",
                    cursor: "pointer",
                }}
                onClick={(e) => {
                    e.preventDefault();
                    navigate("/");
                    window.scrollTo(0, 0)
                }}
            >
                LUSIE & PAUL
                {/* <img
                    className={"main-photo"}
                    src={lalirose}
                    alt="lali rose"
                    width="50px"
                /> */}
            </div>
            <div style={{ marginRight: "40px" }}>
                {auth.currentUser?.uid ?
                    <button
                        className={scrolled ? "white-button" : "black-button"}
                        onClick={(e) => {
                            e.preventDefault();
                            signOut(auth);
                            navigate("/");
                            window.scrollTo(0, 0)
                        }}
                    >
                        SIGN OUT
                    </button>
                :
                    <button
                        className={scrolled ? "white-button" : "black-button"}
                        onClick={(e) => {
                            e.preventDefault();
                            navigate("/auth");
                            window.scrollTo(0, 0)
                        }}
                    >
                        SIGN IN
                    </button>
                }
            </div>
        </nav>
    );
}