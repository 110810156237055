import * as React from 'react';
import engagement from './engagement.jpg';
import greyleigh from './greyleigh-map.png';
import './App.css';
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './authentication/config';
import { Link } from "react-router-dom";
import { Navbar } from './Navbar';
import { WeddingSchedule } from './Schedule';

function SchedulePage() {

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  return (
    <div className="App">
      <Navbar />
      <div className="App-body">
        <WeddingSchedule />  
      </div>
    </div>
  );
}

export default SchedulePage;
