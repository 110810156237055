import * as React from 'react';
import engagement from './engagement.jpg';
import greyleigh from './greyleigh-map.png';
import './App.css';
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './authentication/config';
import { Link } from "react-router-dom";
import { Navbar } from './Navbar';

export function BusSchedule() {
  return (
    <div style={{ display: "flex", flexDirection: "column"}}>
      <div style={{ fontSize: "2.25rem", paddingBottom: "8px" }}>Bus Schedule</div>
      <Entry name={"Kiama Train Station"} details={"Departs 1:30pm"} />
      <Entry name={"Greyleigh"} details={"Departs 9:45pm"} lastEntry={true} />
    </div>
  );
}

export function WeddingSchedule() {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ fontSize: "1.25rem", paddingBottom: "8px" }}>Wedding Schedule</div>
      <Entry name={"Kiama Train Station"} details={"Departs 1:30pm"} />
      <Entry name={"Ceremony"} details={"2:00pm"} />
      <Entry name={"Cocktails on the Lawn"} details={"2:30pm"} />
      <Entry name={"Reception"} details={"5:00pm"} />
      <Entry name={"Food"} details={"5:00pm"} />
      <Entry name={"Cake"} details={"6:30pm"} />
      <Entry name={"Speeches"} details={"7:00pm"} />
      <Entry name={"Dancing"} details={"7:30pm"} />
      <Entry name={"Greyleigh"} details={"Departs 9:45pm"} />
    </div>
  );
}

function Entry({ name, details, lastEntry = false }: { name: string, details: string, lastEntry?: boolean }) {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", borderLeft: "1px solid #FFF", width: "10px", paddingBottom: lastEntry ? "0px" : "24px" }}>
          <div style={{ position: "relative", left: "-5.5px", top: "6px", width: "10px", height: "6px", borderRadius: "50%", border: "2px solid #fff", backgroundColor: "#6e5e54" }} />
        </div>
        <div style={{ display: "flex", textAlign: "start", width: "160px" }}>{name}</div>
      </div>
      <div style={{ display: "flex" }}>{details}</div>
    </div>
  );
}
