import lalirose from './lali_bg.jpg';
import './App.css';
import React, { useState, useMemo, useEffect } from 'react';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import {
  ConfirmationResult,
  RecaptchaVerifier,
  getAuth,
  onAuthStateChanged,
  signInWithPhoneNumber,
  signOut,
} from 'firebase/auth';
import { firebaseConfig } from './authentication/config';
import { useNavigate } from 'react-router-dom';
import { Navbar } from './Navbar';


// reCAPTCHA verification does not work with the Firebase emulator.
// Hence, we do not connect to the emulator in this example.

// We declare variables used on the window object
// We use a custom interface to avoid these modifying the global Window type in other files
interface CustomWindow extends Window {
  signingIn?: boolean;
  verifyingCode?: boolean;
  confirmationResult?: ConfirmationResult | null;
  recaptchaVerifier?: RecaptchaVerifier;
  recaptchaWidgetId?: number;
}

declare const window: CustomWindow;

// Comes from Google reCAPTCHA V3 script included in the HTML file
declare const grecaptcha: any;

/**
 * Returns true if the phone number is valid.
 */
function isAustralianPhoneNumberValid(phoneNumber: string) {
  const pattern = /^04[0-9]{8}$/;
  return phoneNumber.search(pattern) !== -1;
}
function isInternationalPhoneNumberValid(phoneNumber: string) {
  const pattern = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/;
  return phoneNumber.search(pattern) !== -1;
}

/**
 * Returns true if the ReCaptcha is in an OK state.
 */
function isCaptchaOK() {
  if (
    typeof grecaptcha !== 'undefined' &&
    typeof window.recaptchaWidgetId !== 'undefined'
  ) {
    const recaptchaResponse = grecaptcha.getResponse(window.recaptchaWidgetId);
    return recaptchaResponse !== '';
  }
  return false;
}

/**
 * Re-initializes the ReCaptacha widget.
 */
function resetReCaptcha() {
  if (
    typeof grecaptcha !== 'undefined' &&
    typeof window.recaptchaWidgetId !== 'undefined'
  ) {
    grecaptcha.reset(window.recaptchaWidgetId);
  }
}

function PhoneAuthentication() {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const auth = getAuth(app);

  const [token, setToken] = useState("");
  const [user, setUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        console.log("User is signed in");
        setUser(user);
        
        navigate("/dashboard", { relative: "route" });
      } else {
        // User is signed out
        console.log("User is signed out");
        setUser(null);
      }
      setAuthLoading(false); // Set loading to false once the auth state is determined
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
      if (timeLeft - 1 === 0) {
        navigate("/dashboard", { relative: "route" });
      }
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  const [codeInput, setCodeInput] = useState("");
  const [phoneNumberInput, setPhoneNumberInput] = useState("");
  const [showSignInForm, setShowSignInForm] = useState(true);
  const [signInButtonDisabled, setSignInButtonDisabled] = useState(true);
  const [verifyCodeButtonDisabled, setVerifyCodeButtonDisabled] = useState(true);
  const [captchaOK, setCaptchaOK] = useState(false);

  const [signingIn, setSigningIn] = useState(false);
  const [verifyingCode, setVerifyingCode] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult | null>(null);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | null>(null);

  useEffect(() => {
    const initializeRecaptcha = async () => {
      try {
        const verifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
          size: 'normal',
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            setCaptchaOK(true);
            setSignInButtonDisabled(false);
          },
          'expired-callback': () => {
            // Response expired. Ask user to solve reCAPTCHA again.
            setCaptchaOK(false);
            setSignInButtonDisabled(true);
          },
          'error-callback': (error) => {
            // Handle error
            setCaptchaOK(false);
            setSignInButtonDisabled(true);
            console.error('reCAPTCHA error:', error);
          },
        });

        const verification = await verifier.verify();
        setRecaptchaVerifier(verifier);
        setToken(verification);
      } catch (error) {
        console.error('Error initializing reCAPTCHA:', error);
      }
    };

    initializeRecaptcha();
  }, [auth]);


  // window.recaptchaVerifier.render().then(function (widgetId) {
  //   window.recaptchaWidgetId = widgetId;
  // });

  useEffect(() => {
    if (!(auth.currentUser || confirmationResult)) {
      resetReCaptcha();
      setShowSignInForm(true);
    }
  }, [auth.currentUser, confirmationResult]);

  useEffect(() => {
    if (captchaOK === false || (isAustralianPhoneNumberValid(phoneNumberInput) === false && isInternationalPhoneNumberValid(phoneNumberInput) === false) || signingIn === true) {
      setSignInButtonDisabled(true);
    } else {
      onSignIn();
      setSignInButtonDisabled(false);
    }
  }, [grecaptcha, window.recaptchaWidgetId, signingIn, phoneNumberInput]);

  useEffect(() => {
    if (verifyingCode === true || codeInput === "") {
      setVerifyCodeButtonDisabled(true);
    } else {
      setVerifyCodeButtonDisabled(false);
    }
  }, [verifyingCode, codeInput]);

  const navigate = useNavigate();

  function onSignIn() {
    if (captchaOK && (isAustralianPhoneNumberValid(phoneNumberInput) || isInternationalPhoneNumberValid(phoneNumberInput))) {
      console.log("Signing in!");

      setSigningIn(true);
      const appVerifier = recaptchaVerifier;
      const signInNumber = isAustralianPhoneNumberValid(phoneNumberInput) ? phoneNumberInput.replace("0", "+61") : phoneNumberInput;
      signInWithPhoneNumber(auth, signInNumber, appVerifier!)
        .then(function (result) {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          setVerifyCodeButtonDisabled(false);
          setConfirmationResult(result);
          setSigningIn(false);
        })
        .catch(function (error) {
          // Error; SMS not sent
          console.error('Error during signInWithPhoneNumber', error);
          console.log("Error during signInWithPhoneNumber");
          console.log(error);
          window.alert(
            'Error during signInWithPhoneNumber:\n\n' +
              error.code +
              '\n\n' +
              error.message,
          );
          setSigningIn(false);
        });
    } else {
      console.log("Something didnt work");
    }
  }

  if (timeLeft > 0) {
    <div style={{ color: "white" }}>{`Redirecting ... ${timeLeft} seconds`}</div>
  }

  return (
    <div className="App">
      <Navbar />
      <header className="App-header">
        <h2>
          RSVP
        </h2>
      </header>
      <div className="App-body">
          <div>
            <div>
            </div>
              <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <div style={{ fontSize: "1.5rem" }}>Sign in with your phone number below.</div>

                <form id="sign-in-form" onSubmit={(e) => {
                  e.preventDefault();
                  onSignIn();
                }}>
                  <div
                    style={{ display: "flex", flexDirection: "row", gap: "12px" }}
                  >
                    <label htmlFor="phone-number" style={{ fontSize: "1.25rem", width: "180px", textAlign: "end", paddingTop: "0.5rem" }}>Phone number:</label>
                    <input
                      className="input-textfield"
                      autoComplete="off"
                      type="tel"
                      id="phone-number"
                      value={phoneNumberInput}
                      onChange={(e) => setPhoneNumberInput(e.target.value)}
                      disabled={signInButtonDisabled === false || timeLeft > 0}
                    />
                    {/* {(isAustralianPhoneNumberValid(phoneNumberInput) === false && isInternationalPhoneNumberValid(phoneNumberInput) === false) &&
                      <span style={{ color: "red" }}>Input is not an Australian mobile or international phone number. eg. 0411223344 or +61411223344</span>
                    } */}
                  </div>

                  <div style={{ marginTop: "16px", marginBottom: "16px", ...(((isAustralianPhoneNumberValid(phoneNumberInput) === false && isInternationalPhoneNumberValid(phoneNumberInput) === false) || !verifyCodeButtonDisabled || verifyingCode === true || timeLeft > 0) ? { display: "none" } : { display: "flex", justifyContent: "center" }) }} id="recaptcha-container"></div>

                  <input
                    style={{ margin: "16px 0 8px 0", ...(((isAustralianPhoneNumberValid(phoneNumberInput) === false && isInternationalPhoneNumberValid(phoneNumberInput) === false) || !verifyCodeButtonDisabled || verifyingCode === true || timeLeft > 0) ? { display: "none" } : { display: "unset" }) }}
                    className="white-button"
                    type="submit"
                    disabled={signInButtonDisabled || !verifyCodeButtonDisabled || verifyingCode === true || timeLeft > 0}
                    id="sign-in-button"
                    value="SIGN-IN"
                  />
                </form>
              <div>
                <form id="verification-code-form" onSubmit={(e) => {
                  e.preventDefault();
                    console.log("verifying code");
                    if (codeInput !== "") {
                      setVerifyingCode(true);
                      confirmationResult!.confirm(codeInput)
                        .then(function (result) {
                          // User signed in successfully.
                          const user = result.user;
                          console.log("user has signed in");
                          console.log(auth.currentUser);
                          setVerifyingCode(false);
                          setConfirmationResult(null);
                        })
                        .catch(function (error) {
                          // User couldn't sign in (bad verification code?)
                          console.log("user couldnt sign in");
                          console.error('Error while checking the verification code', error);
                          window.alert(
                            'Error while checking the verification code:\n\n' +
                              error.code +
                              '\n\n' +
                              error.message,
                          );
                          setVerifyingCode(false);
                        });
                    }
                }}>
                  <div
                    style={{ display: "flex", flexDirection: "row", gap: "12px" }}
                  >
                    <label htmlFor="verification-code" style={{ fontSize: "1.25rem", width: "180px", textAlign: "end", paddingTop: "0.5rem" }}>
                      SMS code:
                    </label>
                    <input
                      className="input-textfield"
                      autoComplete="off"
                      type="text"
                      id="verification-code"
                      disabled={verifyCodeButtonDisabled || verifyingCode === true || timeLeft > 0}
                      value={codeInput}
                      onChange={(e) => setCodeInput(e.target.value)}
                    />
                  </div>

                  <input
                    style={{ margin: "16px 0 8px 0", ...(verifyingCode ? { display: "none" }: { display: "unset" }) }}
                    className="white-button"
                    type="submit"
                    id="verify-code-button"
                    value="VERIFY CODE"
                    disabled={verifyCodeButtonDisabled || codeInput === "" || verifyingCode === true || timeLeft > 0}
                  />
                </form>
              </div>
            </div>
            {verifyingCode &&
              <div>Verifying Code</div>
            }
        </div>
      </div>
    </div>
  );
}

export default PhoneAuthentication;
