import React, { useEffect, useState } from 'react';
import lalirose from './lali_bg.jpg';
import './App.css';
import {
  getAuth,
  signOut,
} from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './authentication/config';
import { Link } from "react-router-dom";
import { doc, setDoc, getDoc, updateDoc, deleteDoc, getFirestore } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Navbar } from './Navbar';
import { BusSchedule } from './Schedule';

function Dashboard() {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const db = getFirestore(app);
  const navigate = useNavigate();

  const [userDataSaveMessage, setUserDataSaveMessage] = useState<{ status: "none" | "success" | "error" | "loading", message: string }>({ status: "none", message: "" });

  // CREATE or UPDATE user data
  const saveUserData = async () => {
    try {
      const userDoc = doc(db, 'users', auth?.currentUser?.uid); // Reference to the user document
      await setDoc(userDoc, {
        firstName: firstName,
        lastName: lastName ?? "",
        attend: attend ?? false,
        nameOfGuests: nameOfGuests ?? "",
        dietaryRequirements: dietaryRequirements ?? "",
        food: food ?? "mushroom",
        bus: bus ?? false,
      }, { merge: true }); // Merging the data if the document already exists

      console.log("User data saved successfully");
      setUserDataSaveMessage({ status: "success", message: "Saved Successfully" });
    } catch (error) {
      console.error("Error saving user data:", error);
      setUserDataSaveMessage({ status: "error", message: "There was an error saving" });
    }
  };

  // READ user data
  const fetchUserData = async () => {
    try {
      const userDoc = doc(db, 'users', auth?.currentUser?.uid);
      const docSnap = await getDoc(userDoc);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        setFirstName(userData.firstName);
        setLastName(userData.lastName);
        setAttend(userData.attend);
        setNameOfGuests(userData.nameOfGuests);
        setDietaryRequirements(userData.dietaryRequirements);
        setFood(userData.food);
        setBus(userData.bus);
        // setUserData(docSnap.data());
      } else {
        console.log("No user data found!");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    if (!auth?.currentUser?.uid || !auth?.currentUser?.phoneNumber) {
      signOut(auth);
      navigate("/auth");
    } else {
      console.log("Fetching User Data");
      fetchUserData();
    }
    return () => {
      signOut(auth);
    }
  }, [auth?.currentUser]);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [attend, setAttend] = useState(false);
  const [nameOfGuests, setNameOfGuests] = useState('');
  const [dietaryRequirements, setDietaryRequirements] = useState('');
  const [food, setFood] = useState('mushroom');
  const [bus, setBus] = useState(false);
  // const [userData, setUserData] = useState(null);

  return (
    <div className="App">
      <Navbar />
      <header className="App-header">
        <h2>RSVP</h2>
      </header>
      <div className="App-body">
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <div style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
            <div style={{ display: "flex", gap: "16px"}}>
              <div style={{ fontSize: "1.3rem", width: "160px", textAlign: "end", paddingTop: "0.5rem" }}>First Name:</div>
              <input
                className="input-textfield"
                autoComplete="off"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
              />
            </div>
            <div style={{ display: "flex", gap: "16px"}}>
              <div style={{ fontSize: "1.3rem", width: "160px", textAlign: "end", paddingTop: "0.5rem" }}>Last Name:</div>
              <input
                className="input-textfield"
                autoComplete="off"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
              />
            </div>
            <label style={{ display: "flex", gap: "40px"}}>
              <div style={{ fontSize: "1.3rem", width: "160px", textAlign: "end" }}>Attending:</div>
              <div style={{ display: "flex", flexDirection: "row", gap: "16px", alignItems: "center" }}>
                <label className="input-radio">
                  <input
                    type="radio"
                    value="Yes"
                    checked={attend === true}
                    onChange={(e) => setAttend(e.target.value === "Yes")}
                  />
                  <div style={{ fontSize: "1.25rem" }}>Yes</div>
                </label>
                <label className="input-radio">
                  <input
                    type="radio"
                    value="No"
                    checked={attend === false}
                    onChange={(e) => setAttend(e.target.value === "Yes")}
                  />
                  <div style={{ fontSize: "1.25rem" }}>No</div>
                </label>
              </div>
            </label>
            <div style={{ display: "flex", gap: "16px"}}>
              <div style={{ fontSize: "1.3rem", width: "160px", textAlign: "end" }}>Name of guests in your group:</div>
              <input
                className="input-textfield"
                autoComplete="off"
                type="text"
                value={nameOfGuests}
                onChange={(e) => setNameOfGuests(e.target.value)}
                placeholder="Name of guests"
              />
            </div>
            <div style={{ display: "flex", gap: "16px"}}>
              <div style={{ fontSize: "1.3rem", width: "160px", textAlign: "end" }}>Dietary Requirements:</div>
              <input
                className="input-textfield"
                autoComplete="off"
                type="text"
                value={dietaryRequirements}
                onChange={(e) => setDietaryRequirements(e.target.value)}
                placeholder="Dietary Requirements"
              />
            </div>
            {/* <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <div style={{ fontSize: "1.5rem", width: "160px", textAlign: "end" }}>Food Preference:</div>
              <div className="input-select">
                <select value={food} onChange={(e) => setFood(e.target.value)}>
                  <option value="fish">Fish</option>
                  <option value="mushroom">Mushroom</option>
                </select>
              </div>
            </div> */}
            <label style={{ display: "flex", gap: "40px"}}>
              <div style={{ fontSize: "1.3rem", width: "160px", textAlign: "end" }}>Bus:</div>
              <div style={{ display: "flex", flexDirection: "row", gap: "16px", alignItems: "center" }}>
                <label className="input-radio">
                  <input
                    type="radio"
                    value="Yes"
                    checked={bus === true}
                    onChange={(e) => setBus(e.target.value === "Yes")}
                  />
                  <div style={{ fontSize: "1.25rem" }}>Yes</div>
                </label>
                <label className="input-radio">
                  <input
                    type="radio"
                    value="No"
                    checked={bus === false}
                    onChange={(e) => setBus(e.target.value === "Yes")}
                  />
                  <div style={{ fontSize: "1.25rem" }}>No</div>
                </label>
              </div>
            </label>
            <div style={{ display: "flex", flexDirection: "column", gap: "2rem", fontSize: "1.3rem", width: "360px" }}>
              <div style={{ display: "flex", textAlign: "justify", gap: "1rem" }}>
                <span style={{ display: "flex", textAlign: "justify" }}>
                  There is limited parking at Greyleigh, if you would like to drive, please contact Lusie on 04&nbsp;04&nbsp;940&nbsp;727 to ensure availability.
                </span>
              </div>
              <BusSchedule />
            </div>
            <div>
              <button
                className="white-button"
                onClick={() => {
                  setUserDataSaveMessage({ status: "loading", message: "Saved Successfully" });
                  saveUserData();
                }}
                disabled={userDataSaveMessage.status === "loading"}
              >
                {userDataSaveMessage.status === "loading" ?
                  "SAVING"
                :
                  userDataSaveMessage.status === "success" ?
                    "SAVED"
                  :
                    "SAVE"
                }
              </button>
            </div>
            {userDataSaveMessage.status === "success" &&
              <div style={{ fontSize: "1rem", padding: "8px 12px", borderRadius: "4px", marginTop: "12px", border: "1px solid #c3e6cb", background: "#d4edda", color: "#155724" }}>Saved Successfully</div>
            }
            {userDataSaveMessage.status === "error" &&
              <div style={{ fontSize: "1rem", padding: "8px 12px", borderRadius: "4px", marginTop: "12px", border: "1px solid #f5c6cb", background: "#f8d7da", color: "#721c24" }}>Error Saving</div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
